import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Liveness from "./views/Liveness";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/validacao-facial" element={<Liveness />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
