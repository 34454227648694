import axios from "axios";
import { getEnvValue } from "../utils/environmentUtils";

const livenessResource = "/face-liveness";

const portalApi = axios.create({
    baseURL: getEnvValue("REACT_APP_PORTAL_CLIENTE_API_HOST"),
  });

async function criarSessao(token: string) {
    return await portalApi.post(`${livenessResource}/${token}/sessao/criar`);
}

async function consultarResultado(sessionId: string) {
    return await portalApi.get<ResultadoSessaoLivenessResponse>(`${livenessResource}/${sessionId}/resultado`);
}

async function finalizarCaptura(sessionId: string) {
    return await portalApi.post(`${livenessResource}/${sessionId}/finalizar`);
}

export { criarSessao, consultarResultado, finalizarCaptura };
