import React, { CSSProperties, ReactNode } from "react";
import "./style.css";

interface CardProps {
  children: ReactNode;
  style?: CSSProperties
}

const Card = ({ children, style }: CardProps) => {
  return <div className="card" style={style}>
    {children}
  </div>;
};

export default Card;
