import React from 'react';
import './style.css';

interface PrimaryButtonProps {
    onClick: () => void;
    children: string;
}

const PrimaryButton = ({ onClick, children }: PrimaryButtonProps) => {
  return (
    <button className="primary-button" onClick={onClick}>
      {children}
    </button>
  );
};

export default PrimaryButton;