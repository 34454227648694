import React, { CSSProperties, ReactNode } from 'react';
import './style.css';

interface LabelProps {
    children: ReactNode;
    size?: 'sm' | 'md' | 'lg' | 'xg';
    style?: CSSProperties;
}

const Label = ({ children, size, style }: LabelProps) => {
  const sizeClass = size === 'sm' ? 'small' : size === 'md' ? 'medium' : size === 'lg' ? 'large' : size === 'xg' ? 'extra-large' : '';

  return (
    <span className={`label ${sizeClass}`} style={style}>
      {children}
    </span>
  );
};

export default Label;