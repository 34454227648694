import "./style.css";

interface LogoContainerProps {
  children: React.ReactNode;
}

const LogoContainer = ({ children }: LogoContainerProps) => {
  return <div className="logoContainer">{children}</div>;
};

export default LogoContainer;
