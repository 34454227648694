import { Environments } from "../environments/environments";

function getEnvValue(key: string): string {
  if (process.env.REACT_APP_ENVIRONMENT === "dev") {
    return (Environments as Record<string, string>)[`${key}_DEV`];
  }
  if (process.env.REACT_APP_ENVIRONMENT === "prd") {
    return (Environments as Record<string, string>)[key];
  }
  return (Environments as Record<string, string>)[`${key}_LOCAL`];
}

function isProduction(): boolean {
  return process.env.REACT_APP_ENVIRONMENT === "prd";
}

export { getEnvValue, isProduction };
