import { CSSProperties } from "react";

interface SvgIconProps { 
    src: string;
    width: string;
    height:string;
    style?: CSSProperties;
};

const Image = ({ src, width, height, style }: SvgIconProps) => {
  return (
    <>
      <img
        src={`/img/icons/${src}`}
        alt={src}
        width={width}
        height={height}
        style={style}
      />
    </>
  );
};

export default Image;
