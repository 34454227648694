import './style.css';
import LogoContainer from "../components/common/LogoContainer";
import Image from "../components/common/Image";
import { useState } from "react";
import ResultadoValidacaoFacial from "../components/ResultadoValidacaoFacial";
import ValidacaoFacialLiveness from "../components/ValidacaoFacialLiveness";
import Footer from "../components/common/Footer";
import Label from "../components/common/Label";

const Liveness = () => {
  const [resultado, setResultado] =
    useState<ResultadoSessaoLivenessResponse | null>();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const token = urlParams.get("token");

  const getResultado = (resultado: ResultadoSessaoLivenessResponse) => {
    if (resultado !== null) {
      setResultado(resultado);
    }
  };

  const tryAgain = () => {
    setResultado(null);
  };

  return (
    <div style={{minHeight: '100vh', position: 'relative'}}>
      <div className="liveness-content">
        <LogoContainer>
          <Image src="logo.webp" height="100%" width="100%" />
        </LogoContainer>
        <Label size="lg">Validação de prova de vida</Label>
        {resultado ? (
          <ResultadoValidacaoFacial
            resultado={resultado}
            tryAgain={tryAgain}
          ></ResultadoValidacaoFacial>
        ) : (
          token && (
            <ValidacaoFacialLiveness resultado={getResultado} token={token} />
          )
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Liveness;
