import React, { ReactNode } from 'react';

interface SpaceProps {
  children: ReactNode;
  verticalGap?: 'sm' | 'md' | 'lg' | 'xg';
}

const Space = ({ children, verticalGap}: SpaceProps) => {
  const childrenArray = React.Children.toArray(children);
  const size = verticalGap === 'sm' ? '10px' : verticalGap === 'md' ? '16px' : verticalGap === 'lg' ? '20px' : verticalGap === 'xg' ? '28px' : '';

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {childrenArray.map((child, index) => (
        <div key={index} style={{ marginBottom: size }}>
          {child}
        </div>
      ))}
    </div>
  );
};

export default Space;