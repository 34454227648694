import React from "react";
import "./style.css";
import Svg from "../Svg";
import Label from "../Label";

const Footer = ({ exibeTermos = true }) => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer key={1} className="footer">
        <div
          key={2}
          style={{
            width: "90%",
            height: "0px",
            border: "1px solid #004213",
            position: "absolute",
            left: "5%",
            top: "0",
          }}
        ></div>

        <div
          key={3}
          style={{
            background: "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          <>
            <Svg
              src="copyright-icon.svg"
              width="12px"
              height="12px"
              style={{ color: "#004213" }}
            />
            <Label size="sm" style={{ marginLeft: "6px" }}>
              {`${currentYear} 42.544.764/0001-98 - ANTECIPA SOLUCOES FINANCEIRAS E TECNOLOGIA LTDA - Todos os direitos reservados`}
            </Label>
          </>
        </div>
      </footer>
    </>
  );
};

export default Footer;
